const selects = [...document.querySelectorAll("[data-select=true]")];

selects.forEach((select) => {
  if (!(select instanceof HTMLSelectElement)) {
    throw new Error("Element with data-select applied was not a select");
  }

  select.addEventListener("change", () => {
    window.location.pathname = select.value;
  });
});
